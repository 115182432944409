import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/Layout';
import SocialLinks from '../components/SocialLinks';

import * as S from '../components/Post/style';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <S.PostHeader>
      <S.PostTitle>About Me</S.PostTitle>
    </S.PostHeader>
    <S.MainContent>
      <p>
        {`Hello! I'm Eber, a backend developer based in Portugal with several years of experience working both in consulting firms and in product companies. `}
        {`My main specialties are Java and Kotlin with Spring Boot, and I've recently grown interested in Quarkus. I'm also a fan of DevOps, CI/CD and cloud computing. `}
        {`My hobbies include the occasional stroll in the park, exploring new places and sometimes a chess game.`}
      </p>
      <p>{`Please feel free to reach out if you like!`}</p>
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >{`eberjoe@gmail.com`}</p>
    </S.MainContent>
    <SocialLinks insist />
  </Layout>
);

export default AboutPage;
